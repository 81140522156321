<template>
    <div>
        <b-container>
            <section class="profile">
                <b-row>
                   <b-col cols="12" md="4" lg="3">
                       <ProfileNavigation />
                   </b-col>
                    <b-col cols="12" md="8" lg="9">
                        <div class="sector pl-0 pr-0">
                            <h2 class="profile__title mb-4">My profile</h2>
                            <div v-if="getUserProfilePending" class="profile__content">
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <div class="profile__element">
                                            <p class="profile__label">Name</p>
                                            <p class="profile__value">{{ getUserName }}</p>
                                        </div>
                                        <div class="profile__element">
                                            <p class="profile__label">Profile status <span v-if="!getProfileStatus" class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />Check your mailbox/<button v-if="!getProfileStatus" @click.prevent="sendEmailVerification" class="tip__link">Send again</button></span></p>
                                            <p v-if="getProfileStatus" class="profile__status profile__status--positive">confirmed</p>
                                            <p v-else class="profile__status profile__status--negative">unconfirmed</p>
                                        </div>
                                        <div class="profile__element">
                                            <p class="profile__label">Account type <span v-if="getUserProfile[0].type != 4" class="tip"><simple-line-icons icon="info" size="small" color="#504e70" /><router-link to="/sponsorship" class="tip__link">Upgrade now!</router-link></span></p>
                                            <p v-if="getUserProfile[0].type == 1" class="profile__value">Free</p>
                                            <p v-else-if="getUserProfile[0].type == 2" class="profile__value">Standard</p>
                                            <p v-else-if="getUserProfile[0].type == 3" class="profile__value">Premium</p>
                                            <p v-else-if="getUserProfile[0].type == 4" class="profile__value">Ultimate</p>
                                        </div>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <div class="profile__element">
                                            <p class="profile__label">E-mail</p>
                                            <p v-line-clamp:24="1" class="profile__value single-line">{{ getUserEmail }}</p>
                                        </div>
                                        <div class="profile__element">
                                            <p class="profile__label">Subscription status <span v-if="getUserProfile[0].subscription == 1"  class="tip"><simple-line-icons icon="info" size="small" color="#504e70" /><router-link to="/subscription" class="tip__link">Manage subscription</router-link></span><span v-if="getUserProfile[0].subscription == 4"  class="tip"><simple-line-icons icon="info" size="small" color="#504e70" /><router-link to="/sponsorship" class="tip__link">Resume</router-link></span></p>
                                            <p v-if="!getUserProfile[0].subscription" class="profile__status profile__status--neutral">None</p>
                                            <p v-if="getUserProfile[0].subscription == 1" class="profile__status profile__status--positive">Active</p>
                                            <p v-else-if="getUserProfile[0].subscription == 2" class="profile__status profile__status--pending">Pending</p>
                                            <p v-else-if="getUserProfile[0].subscription == 3" class="profile__status profile__status--negative">Inactive</p>
                                            <p v-else-if="getUserProfile[0].subscription == 4" class="profile__status profile__status--cancelled">Cancelled</p>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                            <div v-else class="profile__content">
                                <Spinner size="medium" line-fg-color="#e91e63" />
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import ProfileNavigation from '@/components/ProfileNavigation.vue'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
export default {
  components: {
    SimpleLineIcons,
    ProfileNavigation,
    Spinner
  },
  methods: {
    sendEmailVerification () {
      this.$store.dispatch('sendEmailVerification')
    }
  },
  computed: {
    ...mapGetters([
      'getUserProfile',
      'getUserProfilePending',
      'getUserName',
      'getUserEmail',
      'getProfileStatus'
    ])
  },
  beforeCreate () {
    this.$store.dispatch('getUserProfile')
    this.$store.dispatch('getUserData')
  }
}
</script>
